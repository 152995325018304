import React,{useState} from "react";
import Slider from "react-slick";
import PrimaryButtonLink from "../PrimaryButtonLink";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { Link } from "@inertiajs/inertia-react";

export default function HomePageMainSlider() {
        // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}

    const style = {
        // menuPortal: base => ({ ...base, zIndex: 9999 }),
        control: (base,state) => ({
          ...base,
          border: "0px solid black",
          outline:'none',
          // This line disable the blue border
          boxShadow: "none"
        })
    }
    var settings = {
        dots: true,
        infinite: true,
        fade: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        autoplay: true,
    };
    const [psychicSearchedList, setPsychicSearchedList] = useState([]);

    const formatOptionLabel = ({
        id,
        profile_photo_path,
        display_name,
        name,
        speciality_name,
    }) => (
        <Link href={route("psychic.details", id)}>
            <div className="flex justify-left space-x-2 align-items-center text-black">
                <div className="">
                    <img
                        className="w-12 h-12 rounded-full"
                        src={window.location.origin + "/" + profile_photo_path}
                    />
                </div>
                <div className="">
                    <div className="font-bold">{display_name}</div>
                </div>
            </div>
        </Link>
    );
    const handleSelectKeyDown = (event) => {
        if (event.target.value.length > 2 && event.key === "Enter") {
            var foundSearchObj = psychicSearchedList.filter((e) =>
                e.display_name
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
            );
            if (foundSearchObj == "") {
                window.location.href = route("psychic", {
                    search: event.target.value,
                });
            }
        }
    };
    const handleSelectOnChange = (event) => {
        window.location.href = route("psychic.details", { id: event.id });
    };
    const loadOptions = (inputValue) => {
        if (inputValue.length < 3) return;
        return axios
            .get(route("psychic", { search: inputValue }))
            .then((res) => {
                setPsychicSearchedList(res.data);
                return res.data;
            })
            .catch((err) => {
                console.log("erro psychic", err);
            });
    };
    return (
        <div>
            <Slider {...settings}>
                <div>
                    <div
                        className=" h-screen d-flex align-items-center"
                        style={{
                            backgroundImage:
                                "url(/assets/img/home/slider/slider-1.jpg)",
                                backgroundRepeat:"no-repeat",
                                backgroundSize:"cover"
                        }}
                    >
                        <div className="container">
                            <div className="">
                                <div className=" pt-12 lg:w-max md:w-max sm:w-auto xs:w-auto">
                                    <div className="">
                                        <div className=" mb-4">
                                            <h1
                                                data-animation="fadeInUp"
                                                data-delay=".4s"
                                                className="text-white font-bold mb-0 text-[40px] "
                                            >
                                                EMBRACE YOUR DESTINY
                                            </h1>
                                            <span className="text-xl text-white bg-secondary font-bolder rounded px-2 py-1 text-justify ">Get the Answers you Need with Our Expert Psychics</span>
                                        </div>
                                        <div className="grid gird-cols-1">
                                        <div className="w-full">
                                         <AsyncSelect
                                         style={style}
                                          className="w-full custom-async-select"
                                          menuPortalTarget={document.body} 
    // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            placeholder="Type 3 letters to Search Psychic"
                                            cacheOptions
                                            defaultOptions
                                            getOptionLabel={(e) => e.display_name}
                                            getOptionValue={(e) => e.id}
                                            loadOptions={loadOptions}
                                            formatOptionLabel={formatOptionLabel}
                                            onKeyDown={handleSelectKeyDown}
                                            onChange={handleSelectOnChange}
                                        />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}
