import React, { useEffect, useState } from "react";
import { Link, usePage } from "@inertiajs/inertia-react";
import ApplicationLogo from "../ApplicationLogo";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomePageMainSlider from "../Sliders/HomePageMainSlider";
import Breadcrumbs from "../Breadcrumbs";
import NavbarMain from "./NavbarMain";
import NavbarDashboard from "./NavbarDashboard";
import { useSelector } from "react-redux";
import { getActiveChat } from "@/ReduxToolkit/chatSlice";
import { getUserRole } from "./GenericFunctions";
import AddBalancePopup from "../Popups/AddBalancePopup";
import PrimaryButton from "../PrimaryButton";
import SecondaryButtonLink from "../SecondaryButtonLink";
import OneTimePopup from "../Popups/OneTimePopup";

export default function HeaderHome({
    USER_ROLE,
    page,
    auth,
    header,
    unSeenChats,
    children,
}) {
    const specialities = usePage().props.specialities;
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupsDetail, setPopupsDetail] = useState([]);
    const maxScroll = 1;
    const active_chat = useSelector(getActiveChat);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        console.log("active_chat", active_chat);
        $.ajaxSetup({
            headers: {
                "X-CSRF-TOKEN": auth.csrf_token,
            },
        });
        if (auth.user) setInterval(authCheck, 100000);
        window.addEventListener("scroll", handleScroll, { passive: true });
        $(".mobile-menu").html("");
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [auth.user]);

    const authCheck = function () {
        axios
            .post(route("auth.check"))
            .then((response) => {
                // Check if the response includes user data
                if (response.data && response.data.id) {
                    // User is authenticated, handle user data here
                    console.log(response.data);
                } else {
                    // User is not authenticated, handle the error message
                    console.log("Authentication failed");
                }
            })
            .catch((error) => {
                // Handle errors and status codes here
                if (error.response) {
                    if (error.response.status === 401) {
                        console.log("Unauthorized access. Please log in.");
                        // You can perform a logout action here if needed
                    } else if (error.response.status === 419) {
                        console.log("Page expired. Reloading the page.");
                        location.reload();
                    } else {
                        console.error("An error occurred:", error);
                    }
                } else {
                    console.error("An error occurred:", error);
                }
            });
    };
    return (
        <div>
            <AddBalancePopup
                closePopup={() => {
                    setIsPopupOpen(false);
                }}
                isOpen={isPopupOpen}
            />
            <header className="">
                <div
                    className={`header-menu-area fixed  pb-2 z-1 ${
                        scrollPosition < maxScroll
                            ? "transparrent-header "
                            : " w-full bg-gray-50 top-0 bg-opacity-80 shadow-lg z-[101]"
                    } fixed-scrollable `}
                >
                    <div className="container-fluid h-100 p-0">
                        <div className="d-flex w-full flex-col align-items-center h-100">
                            <div className="flex-1 w-full">
                                {active_chat && active_chat.id && (
                                    <div className="text-center bg-black">
                                        <p className="font-bold text-sm md:text-lg py-1 tracking-[0.3em]">
                                            <span className="text-white">
                                                You have active paid chat.
                                            </span>
                                            <a
                                                className="bg-white px-4 py-0 rounded bg-white text-black font-extraBold"
                                                href={route(
                                                    "chatify",
                                                    getUserRole() == "psychic"
                                                        ? active_chat.member_id
                                                        : active_chat.psychic_id
                                                )}
                                            >
                                                Continue Chat
                                            </a>
                                        </p>
                                    </div>
                                )}
                                <div className="py-1 flex flex-wrap  gap-1 md:gap-4 items-center justify-center text-center bg-[#540015]">
                                    <div className="font-bold text-sm md:text-normal text-white py-1 uppercase flex-1 md:flex-none">
                                        {window.constants.announcement_text}
                                    </div>
                                    <div className="">
                                        {(getUserRole() == undefined ||
                                            getUserRole() == "member") && (
                                            <SecondaryButtonLink
                                                className="!py-[5px] md:py-2 px-2"
                                                href={route(
                                                    "member.accounts.balance"
                                                )}
                                            >
                                                Buy Now
                                            </SecondaryButtonLink>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="container flex-1 d-flex flex-wrap w-full items-center justify-between">
                                <div className="d-flex align-items-center ">
                                    <div className="logo  ml-2 pos-rel">
                                        <Link className="" href={route("home")}>
                                            <ApplicationLogo
                                                className={`${
                                                    scrollPosition < maxScroll
                                                        ? "w-28"
                                                        : "w-20"
                                                }`}
                                                isWhiteTransparent={false}
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex-1 text-center block md:hidden">
                                    {getUserRole() == "member" && (
                                        <div className="order-1 md:order-2">
                                            <PrimaryButton
                                                className="px-4 py-1"
                                                onClick={() => {
                                                    setIsPopupOpen(true);
                                                }}
                                            >
                                                Credit+
                                            </PrimaryButton>
                                        </div>
                                    )}
                                </div>
                                <div className="">
                                    <div className="d-flex align-items-center justify-end">
                                        {auth.role &&
                                        auth.role?.[0]?.name == USER_ROLE ? (
                                            <NavbarDashboard
                                                isPopupOpen={isPopupOpen}
                                                setIsPopupOpen={setIsPopupOpen}
                                                USER_ROLE={USER_ROLE}
                                                unSeenChats={unSeenChats}
                                                auth={auth}
                                            />
                                        ) : (
                                            <NavbarMain
                                                isPopupOpen={isPopupOpen}
                                                setIsPopupOpen={setIsPopupOpen}
                                                unSeenChats={unSeenChats}
                                                auth={auth}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {page == "home" ? (
                    <HomePageMainSlider />
                ) : (
                    <Breadcrumbs header={header} />
                )}
            </header>
        </div>
    );
}
