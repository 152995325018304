import React, { useState, useEffect } from "react";
import InputLabel from "../InputLabel";
import TextInput from "../TextInput";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import Card from "../Card";

export default function AddBalanceForm(props) {
    const { userBalanceList, closePopup } = props;
    const [userBalList, setUserBalList] = useState(userBalanceList);
    const [plansList, setPlansList] = useState([]);
    const [amount, setAmount] = useState(2.0);
    let transaction_amount = 2;
    let planId = 0;
    let transaction_desc = "";
    let description = "$" + amount + " balance purchase from KISMAA.COM";
    const initialOptions = {
        "client-id":
            "AXrEuOuoDoo_6qKdowofgAnqE2AQMBmcr5no-5gVqbabOn6eg_vcDKpSiRuFKTfunkqhkxfMZdBZyl-8",
        currency: "USD",
        intent: "capture",
    };
    useEffect(() => {
        getUserBalanceHistory();
        getPlans();
    }, []);
    const handleAmountChange = (event) => {
        setAmount(event.target.value);
        description =
            "$" + event.target.value + " balance purchase from KISMAA.COM";
    };
    const getPlans = () => {
        axios
            .post(route("getPlans"))
            .then((response) => {
                console.log(response);
                setPlansList(response.data);
            })
            .catch((error) => {
                console.log(error);
                return fail(error);
            });
    };
    const getUserBalanceHistory = () => {
        axios
            .post(route("getBalance"))
            .then((response) => {
                console.log(response);
                setUserBalList(response.data);
            })
            .catch((error) => {
                console.log(error);
                return fail(error);
            });
    };

    const addBalance = (data) => {
        axios
            .post(route("member.accounts.addBalance"), {
                paypal_response: data,
                amount: transaction_amount,
                plan_id: planId,
                description: transaction_desc,
                payment_type: "balance",
            })
            .then((response) => {
                console.log("add_balance_history", response);
                if (response.data.result == "Success") {
                    getUserBalanceHistory();
                    closePopup();
                }
                // return success(response);
            })
            .catch((error) => {
                console.log(error);
                return fail(error);
            });
    };
    // PAYPAL FUNCTIONS
    const createOrder = (data, actions, plan_id, price, title = "") => {
        if (plan_id != 0) {
            transaction_desc = description + " against PLAN: " + title;
        } else {
            transaction_desc = description;
        }
        transaction_amount = price;
        planId = plan_id;
        return actions.order
            .create({
                purchase_units: [
                    {
                        description: transaction_desc,
                        amount: {
                            currency_code: initialOptions.currency,
                            value: transaction_amount,
                        },
                    },
                ],
            })
            .then((orderId) => {
                console.log("orderId", orderId);
                console.log("order creation", data);
                // Your code here after creating the order
                return orderId;
            });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(() => {
            // Your code here after capturing the order
            console.log(data);
            console.log(actions);
            addBalance(data);
        });
    };
    return (
        <>
            <div className="w-full gap-12 flex flex-wrap pb-4">
                {plansList.map((plan, index) => (
                    <div
                        className="mt-4 relative bg-gradient-to-r from-[#540048] via-[#A1228D]  to-[#540048] hover:shadow-2xl border-[#A1228D] hover:border-[#F2BA36] border-8 ease-in-out duration-300 border p-12 rounded-lg  text-center flex-1 flex flex-col gap-12"
                        key={index}
                    >
                        <p className="rounded-full bg-white px-4 border border-[#540048] font-bold d-flex absolute top-[-20px] left-[50%] -translate-x-[50%] uppercase">
                            {plan.badge_title}
                        </p>
                        <h3 className=" text-[#F2BA36] font-bold">
                            Earn ${plan.free_credit}
                        </h3>
                        <h2 className="text-white font-bold d-flex">
                            {plan.title}
                        </h2>
                        <div>
                        <PayPalScriptProvider options={initialOptions}>
                            {/* PayPal Button */}
                            <PayPalButtons
                                key={`paypal-plan-button-${plan.price}`}
                                style={{ layout: "horizontal" }}
                                fundingSource="paypal"
                                createOrder={(data, actions) =>
                                    createOrder(
                                        data,
                                        actions,
                                        plan.id,
                                        plan.price,
                                        plan.title
                                    )
                                } // Pass plan id and amount to createOrder function
                                onApprove={onApprove}
                            />
                            {/* Card Button */}
                            <PayPalButtons
                                key={`card-plan-button-${plan.price}`}
                                style={{ layout: "horizontal" }}
                                fundingSource="card"
                                createOrder={(data, actions) =>
                                    createOrder(
                                        data,
                                        actions,
                                        plan.id,
                                        plan.price,
                                        plan.title
                                    )
                                }
                                onApprove={onApprove}
                            />
                        </PayPalScriptProvider>
                        </div>
                    </div>
                ))}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-12">
                <div className="col-span-1">
                    <h4 className="font-bold d-flex">
                        Current Balance (Free + Paid):
                        <br />$
                        {userBalList && userBalList[0]
                            ? userBalList[0].current_balance +
                              " ($" +
                              userBalList[0].free_current_balance +
                              " + $" +
                              userBalList[0].paid_current_balance +
                              ")"
                            : "0"}
                    </h4>
                </div>
                <div className="col-span-1 border rounded p-4">
                    <h4 className="font-bold">Add Balance</h4>
                    <div className="d-flex flex-wrap align-items-center gap-4 justify-content-center">
                        <div className="w-100">
                            <InputLabel forInput="amount" value="Amount" />

                            <TextInput
                                type="number"
                                id="amount"
                                name="amount"
                                value={amount}
                                handleChange={handleAmountChange}
                                className="mt-1 block w-full"
                                required
                            />
                        </div>
                        <div className="w-100">
                            <PayPalScriptProvider options={initialOptions}>
                                {/* PayPal Button */}
                                <PayPalButtons
                                    key={`paypal-button-${amount}`}
                                    style={{ layout: "horizontal" }}
                                    fundingSource="paypal"
                                    createOrder={(data, actions) =>
                                        createOrder(data, actions, 0, amount)
                                    }
                                    onApprove={onApprove}
                                />

                                {/* Card Button */}
                                <PayPalButtons
                                    key={`card-button-${amount}`}
                                    style={{ layout: "horizontal" }}
                                    fundingSource="card"
                                    createOrder={(data, actions) =>
                                        createOrder(data, actions, 0, amount)
                                    }
                                    onApprove={onApprove}
                                />
                            </PayPalScriptProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
