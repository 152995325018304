import React, { useState, useEffect } from "react";
import { getUserRole } from "../Shared/GenericFunctions";
import { Link } from "@inertiajs/inertia-react";

const OneTimePopup = (props) => {
    const { isOpen, closePopup } = props;
    const [plansList, setPlansList] = useState([]);
    useEffect(() => {
        if (isOpen) {
            getPlans();
        }
    }, [isOpen]);

    const getPlans = () => {
        axios
            .post(route("getPlans"))
            .then((response) => {
                console.log(response);
                setPlansList(response.data);
            })
            .catch((error) => {
                console.log(error);
                // Handle error
            });
    };

    if (!isOpen) {
        return null;
    }
    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-[102] bg-black bg-opacity-30 ">
                <div className="bg-white p-6 rounded shadow-lg mx-8 my-auto max-w-[100%] md:max-w-[60%] max-h-[90vh]">
                    <div className="flex justify-end">
                        <button
                            className="text-black hover:font-semibold focus:outline-none"
                            onClick={closePopup}
                        >
                            X
                        </button>
                    </div>
                    <div className="h-100 max-h-full flex flex-col gap-4">
                        <h2 className="font-bold text-center text-4xl">Get MORE in LESS</h2>
                        <div className="w-full h-100 max-h-96 overflow-y-auto gap-2 flex flex-wrap pb-4">
                            {plansList.map((plan, index) => (
                                <Link className="flex-1" href={getUserRole() === 'member' || getUserRole() === null ? route('member.accounts.balance') : route(getUserRole()+'.profile.edit')} rel="noopener noreferrer">:
                                <div
                                    className="mt-4 relative bg-gradient-to-r from-[#540048] via-[#A1228D]  to-[#540048] hover:shadow-2xl border-[#A1228D] hover:border-[#F2BA36] border-8 ease-in-out duration-300 border px-6 py-12 rounded-lg  text-center flex-1 flex flex-col gap-12"
                                    key={index}
                                >
                                    <p className="rounded-full bg-white px-4 border border-[#540048] font-bold d-flex absolute top-[-20px] left-[50%] -translate-x-[50%] uppercase">
                                        {plan.badge_title}
                                    </p>
                                    <h2 className=" text-[#F2BA36] font-bold">
                                        Earn ${plan.free_credit}
                                    </h2>
                                    <h3 className="text-white font-bold d-flex">
                                        {plan.title}
                                    </h3>
                                </div>
                                </Link>

                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default OneTimePopup;
