import React from "react";
import { Link } from "@inertiajs/inertia-react";
import ApplicationLogo from "../ApplicationLogo";
import PrimaryButtonLink from "../PrimaryButtonLink";
import SecondaryButtonLink from "../SecondaryButtonLink";

export default function Footer({ auth, header, children }) {
    return (
        <div>
            <footer>
                <div className="footer-top bg-secondary py-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="footer-contact-info">
                                    <div className="py-4">
                                        <Link
                                            className="flex flex-wrap items-center justify-center "
                                            href={route("home")}
                                        >
                                            <ApplicationLogo className="w-60" />
                                        </Link>
                                    </div>
                                    <div className=" grid grid-cols-2 text-center clear-both content-evenly">
                                        <p className="float-left">
                                            <i className="fa fa-envelope"></i>{" "}
                                            info@kismaa.com
                                        </p>
                                        <div className="float-right">
                                            <div className="text-center">
                                                <span className="p-3">
                                                    <a href="https://www.facebook.com/KismaaPsychics/" target="_blank"><i className="fa-2x fa-brands fa-facebook fa-beat"></i></a>
                                                </span>
                                                <span className="p-3">
                                                <a href="https://www.instagram.com/kismaapsychics/" target="_blank"><i className="fa-2x fa-brands fa-instagram fa-beat"></i></a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear-both pt-4"></div>
                                </div>
                            </div>
                            <div className="col-md-8 grid grid-cols-1 content-evenly">
                                <div className="footer-widget ">
                                <div className="row py-4">
                                    <div className="col-md-9">
                                    <div className="">
                                        <h4 className="text-white">
                                            Are you a Psychic?
                                        </h4>
                                    </div>
                                    <div className="footer-menu pt-2">
                                        <p className="text-white float-left">
                                            Join Kismaa today to engage with
                                            thousands of new clients via online
                                            chat.
                                        </p>
                                    </div>
                                    </div>
                                    <div className="col-md-3 ">
                                    <div className="lg:float-right pt-0 sm:pt-2 text-center">
                                            <SecondaryButtonLink
                                                href={route("psychic")}
                                            >
                                                Read More
                                            </SecondaryButtonLink>
                                        </div>
                                    </div>

                                </div>
                                </div>
                                <div className="footer-widget  clear-both">
                                    <div>
                                        <h4 className="text-white">
                                            Quick Links
                                        </h4>
                                    </div>
                                    <div className="footer-menu pt-2">
                                        <ul className="flex flex-wrap items-center justify-left ">
                                            <li className="mx-4 text-white">
                                                <Link
                                                    className="text-white"
                                                    href={route("aboutUs")}
                                                >
                                                    About Us
                                                </Link>
                                            </li>
                                            <li className="mx-4 text-white">
                                                <Link
                                                    className="text-white"
                                                    href={route(
                                                        "privacyPolicy"
                                                    )}
                                                >
                                                    Privacy Policy
                                                </Link>
                                            </li>
                                            <li className="mx-4 text-white">
                                                <Link
                                                    className="text-white"
                                                    href={route("termsOfUse")}
                                                >
                                                    Terms of Use
                                                </Link>
                                            </li>
                                            <li className="mx-4 text-white">
                                                <Link
                                                    className="text-white"
                                                    href={route("refundPolicy")}
                                                >
                                                    Refund Policy
                                                </Link>
                                            </li>
                                            <li className="mx-4 text-white">
                                                <Link
                                                    className="text-white"
                                                    href={route("faq")}
                                                >
                                                    FAQ
                                                </Link>
                                            </li>

                                            <li className="mx-4 text-white">
                                                <Link
                                                    className="text-white"
                                                    href={route("contactUs")}
                                                >
                                                    Contact Us
                                                </Link>
                                            </li>
                                            <li className="mx-4 text-white">
                                                <Link
                                                    className="text-white"
                                                    href={route("certificates")}
                                                >
                                                    Certificates
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="flex text-white">
                                All prices are listed in $ credits. One $ credit is equivalent to $1 USD. Psychics are not employees or representatives of Kismaa Psychics.
                                </div>
                                <div className="flex flex-wrap">
                                    <a href="https://play.google.com/store/apps/details?id=com.kismaa.kismaa" target="_blank">
                                        <img className="w-60" src="/assets/img/playstore.png" alt="Play Store" />
                                    </a>
                                    <a  href="https://apps.apple.com/us/app/kismaa/id6479958292"  target="_blank">
                                        <img className="w-60" src="/assets/img/appstore.png" alt="App Store" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom pt-25 pb-15 bg-[#194148]">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="footer-copyright text-center">
                                    <p className="text-white">
                                        <span>Copyright by@ Kismaa - 2024</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}
