import { Link, usePage } from "@inertiajs/inertia-react";
import React, { useEffect, useState } from "react";
import ToggleButton from "../ToggleButton";
import PrimaryButton from "../PrimaryButton";
import AddBalancePopup from "../Popups/AddBalancePopup";
import { getUserRole } from "./GenericFunctions";
import ProfileNavbar from "./ProfileNavbar";

export default function NavbarDashboard(props) {
    const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY;
    const PUSHER_APP_CLUSTER = import.meta.env.VITE_PUSHER_APP_CLUSTER;
    const PUSHER_APP_CHANNEL = import.meta.env.VITE_PUSHER_APP_CHANNEL;
    const USER_ROLE = getUserRole();

    const { auth, unSeenChats, setIsPopupOpen } = props;
    const specialities = usePage().props.specialities;
    const [scrollPosition, setScrollPosition] = useState(0);
    const [menuVisible, setMenuVisible] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState({}); // State to track submenu open/close

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };
    const handleSubMenuToggle = (parent_speciality_id) => {
        const updatedSubMenuOpen = {};
        // Close all submenus
        for (const key in subMenuOpen) {
            updatedSubMenuOpen[key] = false;
        }
        // Open the clicked submenu
        updatedSubMenuOpen[parent_speciality_id] =
            !subMenuOpen[parent_speciality_id];
        setSubMenuOpen(updatedSubMenuOpen);
    };

    const isSubMenuOpen = (parent_speciality_id) => {
        return subMenuOpen[parent_speciality_id] || false;
    };
    const maxScroll = 1;
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const getParentSpecialities = () => {
        return specialities.filter((e) => e.parent_speciality_id == null);
    };

    const hasChildSpecialities = (parent_speciality_id) => {};
    const getChildSpecialities = (parent_speciality_id) => {
        return specialities.filter(
            (e) => e.parent_speciality_id == parent_speciality_id
        );
    };

    return (
        <>

            <div
                onClick={toggleMenu}
                className="w-min float-right block md:hidden border-2 p-2 "
            >
                <i className="fa-lg fa-solid fa-bars"></i>
            </div>
            <div
                className={`main__menu__container max-h-screen  items-center ${
                    menuVisible ? "fixed left-0 w-full mt-24" : "hidden md:flex"
                }  md:relative`}
            >
                <div className="main__menu flex gap-4 flex-col md:flex-row items-center py-4 md:py-0 w-full md:w-fit bg-white md:bg-transparent absolute md:relative">
                    <div className="font-bold order-2 md:order-0">
                        <Link href={route("home")}>Main Page</Link>
                    </div>
                    {getUserRole() == "psychic" && (
                    <div className="font-bold order-2 md:order-0">
                            <Link href={route(getUserRole() + ".dashboard")}>
                                Dashboard
                            </Link>
                        </div>
                    )}
                    {auth.user &&
                        auth.role != null &&
                        getUserRole() == "admin" && (
                            <>
                                <div className="font-bold order-2 md:order-0">
                                    <Link href={route("admin.member")}>
                                        Members
                                    </Link>
                                </div>
                                <div className="font-bold order-2 md:order-0">

                                    <Link href={route("admin.psychic")}>
                                        Psychics
                                    </Link>
                                </div>
                            </>
                        )}
                    {auth.user &&
                        auth.role != null &&
                        getUserRole() == "member" && (
                    <div className="font-bold order-2 md:order-0">

                                <Link href={route("member.accounts.balance")}>
                                    Wallet
                                </Link>
                            </div>
                        )}
                    {auth.user &&
                        auth.role != null &&
                        getUserRole() == "psychic" && (
                            <>
                                <div className="font-bold order-2 md:order-0">
                                    <Link href={route("psychic.earnings")}>
                                        Earnings
                                    </Link>
                                </div>
                                <div className="font-bold order-2 md:order-0">

                                    <Link href={route(USER_ROLE + ".warnings")}>
                                        Warnings
                                    </Link>
                                </div>

                                <div className="font-bold order-1 md:order-0">
                                    <Link
                                        href={route(
                                            USER_ROLE + ".profile.edit"
                                        )}
                                    >
                                        Profile
                                    </Link>
                                </div>
                            </>
                        )}
                    {auth.user &&
                        auth.role != null &&
                        (getUserRole() == "psychic" ||
                            getUserRole() == "member") && (
                            <div className="order-2 md:order-0 w-fit font-bold">
                                <Link href={route("chatify")}>
                                    Chat{" "}
                                    {unSeenChats ? (
                                        <span className="badge badge-pill badge-danger">
                                            {unSeenChats}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </Link>
                            </div>
                        )}
                    <div className="order-1 md:order-2">
                        {getUserRole() == "psychic" && (
                            <ToggleButton auth={auth} />
                        )}
                    </div>
                    {getUserRole() == "member" && (
                        <div className="hidden md:block order-1 md:order-2">
                            <PrimaryButton
                                onClick={() => {
                                    setIsPopupOpen(true);
                                }}
                            >
                                Buy Credit
                            </PrimaryButton>
                        </div>
                    )}
                    <ProfileNavbar auth={auth} />
                </div>
            </div>
        </>
    );
}
