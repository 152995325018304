import { useEffect, useState, useRef } from "react";
import { useForm } from "@inertiajs/inertia-react";
import InputError from "../InputError";
import InputLabel from "../InputLabel";
import PrimaryButton from "../PrimaryButton";
import TextInput from "../TextInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PrimaryButtonLink from "../PrimaryButtonLink";
import SecondaryButton from "../SecondaryButton";
import { useSelector } from 'react-redux';
import { getIsStartChatPopupOpen, getConfirmUserPopupDetails } from './../../ReduxToolkit/chatSlice';

const StartChatConfirmation = (props) => {
    const { audioRef, closePopup, handleConfirmation } = props;

    // Use useSelector directly inside the component
    const isOpen = useSelector(getIsStartChatPopupOpen);
    const popupData = useSelector(getConfirmUserPopupDetails);
    useEffect(() => {;
        console.log('StartChatConfirmation',isOpen);
        if (audioRef && audioRef.current) {
            if (isOpen) {
                audioRef.current.loop = true;
                audioRef.current.play().catch((error) => {
                    // Handle any errors that occur during playback
                    console.error("Error playing audio:", error);
                });
            } else {
            // Pause and reset the audio when the popup is closed
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        }
        console.log('StartChatConfirmation', isOpen);
        // Clean up audio when component unmounts
        return () => {
            if (audioRef && audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, [isOpen]);
    return (
        <>
            <div
                className={`fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-30 z-20 ${
                    isOpen ? "" : "hidden"
                }`}
            >
                <div className="bg-white p-6 rounded shadow-lg w-4/5 md:w-2/6 ">
                    <div className="flex flex-col gap-4 w-full">
                        <p className="text-xl">
                            <strong>Starting chat with Member: {popupData?.name}</strong>
                        </p>
                        <p>Chat Request:</p>
                        <div className="flex gap-4 justify-center flex-wrap  ">
                            <SecondaryButton onClick={closePopup}>
                                Decline
                            </SecondaryButton>
                            <PrimaryButton
                                onClick={(e) => {
                                    handleConfirmation(popupData.member_id);
                                }}
                            >
                                Start Chat
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default StartChatConfirmation;
