import { getPusherInstance, setActiveStatus } from "@/Helper/pusher";
import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";

export default function ToggleButton(props) {
    var pusherObj = getPusherInstance();

    const { auth } = props;
    const [isActive, setIsActive] = useState(
        auth.user ? auth.user.active_status : 0
    );
    const handleToggle = () => {
        setIsActive(!isActive);
        setActiveStatus(!isActive ? 1 : 0, auth.user ? auth.user.id : 0);
        if (!isActive) {
            pusherObj.subscribe("presence-activeStatus");
        } else {
            pusherObj.unsubscribe("presence-activeStatus");
        }
    };
    useEffect(() => {
        setIsActive(auth.user ? auth.user.active_status : 0);
    }, [auth]);
    return (
        <div
            className={`toggle_active_status_container relative inline-flex items-center justify-between w-12 h-6 ${
                isActive ? "bg-green-500" : "bg-gray-500"
            } rounded-full cursor-pointer`}
        >
            <div
                id="toggle_active_status"
                className={`w-4 h-4 rounded-full bg-white shadow-md transform transition-transform duration-300 ease-in-out ${
                    isActive ? "translate-x-6" : "translate-x-2"
                }`}
                onClick={handleToggle}
            ></div>
        </div>
    );
}
