import { Link } from "@inertiajs/inertia-react";

export default function SecondaryButtonLink({ className = "", children, href }) {
    return (
        <Link
            href={href}
            className={
                `inline-flex items-center px-10 py-3 bg-slate-100 hover:shadow-lg hover:border-white border border-transparent rounded-md font-semibold text-xs text-black uppercase hover:text-white active:text-white focus:text-white  hover:bg-secondary  transition ease-in-out duration-150 ` +
                className
            }
        >
            {children}
        </Link>
    );
}
