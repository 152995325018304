import React from "react";

export default function Breadcrumbs({ header }) {
    return (
        header && (
            <div>
                <section className="breadcrumb-bg pt-40 md:pt-28 pb-4">
                    <div className="container  mb-2 pb-0">
                        <div className="row ">
                            <div className="col-lg-12 col-md-12">
                                <div className="page-title">
                                    <h1 className="text-[35px] font-bold pt-2 text-center">
                                        {header}
                                    </h1>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 d-flex align-items-center  ">
                                <div className="page-breadcumb d-none">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb ">
                                            <li className="breadcrumb-item">
                                                <a href="index.html">Home</a>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                Login
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    );
}
