import { Link } from "@inertiajs/inertia-react";
import React,{useState,useEffect} from "react";
import { getUserRole } from "./GenericFunctions";
import PrimaryButton from "../PrimaryButton";
import { getPusherInstance, setActiveStatus } from "@/Helper/pusher";

export default function ProfileNavbar(props) {
    const { auth } = props;
    const [subMenuOpen, setSubMenuOpen] = useState({}); // State to track submenu open/close
    const handleSubMenuToggle = (parent_speciality_id) => {
        const updatedSubMenuOpen = {};
        // Close all submenus
        for (const key in subMenuOpen) {
            updatedSubMenuOpen[key] = false;
        }
        // Open the clicked submenu
        updatedSubMenuOpen[parent_speciality_id] =
            !subMenuOpen[parent_speciality_id];
        setSubMenuOpen(updatedSubMenuOpen);
    };
    const handleLogout = (e) => {
        e.preventDefault();
        var pusherObj = getPusherInstance();
        setActiveStatus(0, auth.user.id);
        var activeStatusChannel = pusherObj.unsubscribe(
            "presence-activeStatus"
        );
        $("#logoutHidden")[0].click();
    };

    const isSubMenuOpen = (parent_speciality_id) => {
        return subMenuOpen[parent_speciality_id] || false;
    };
    return (
        <>
        <Link
                id="logoutHidden"
                className="d-none"
                href={route("logout")}
                method="post"
                as="button"
            ></Link>
            {auth && auth.user == null && (
                <>
                    <div className=" order-2">
                        <Link
                            className="font-bold rounded border-danger"
                            href={route("login")}
                        >
                            Login
                        </Link>
                    </div>
                    <div
                        className="parent__menu relative flex flex-col md:flex-row items-center order-2 md:order-0"
                        onClick={() => handleSubMenuToggle("sign_up")}
                    >
                        <PrimaryButton className="flex gap-2 items-center justify-center font-bold hover:color-secondary-400 cursor-pointer">
                            Sign Up<i className="fa-solid fa-caret-down"></i>
                        </PrimaryButton>
                        <div
                            className={`${
                                isSubMenuOpen("sign_up")
                                    ? "md:absolute"
                                    : "hidden"
                            } text-black right-0 min-w-[150px]  shadow-none md:shadow-xl bg-white rounded justify-center py-2 px-4 flex flex-col gap-2 w-max mt-0 md:mt-4 top-0 md:top-8`}
                        >
                            <div>
                                <Link
                                    href={route("register.create", {
                                        step: "step-1",
                                        role: "psychic",
                                    })}
                                >
                                    Psychic
                                </Link>
                            </div>

                            <div>
                                <Link
                                    href={route("register.create", {
                                        step: "step-1",
                                        role: "member",
                                    })}
                                >
                                    Member
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {auth && auth.user && (
                <div
                    className="parent__menu relative flex flex-col md:flex-row items-center order-0 md:order-2"
                    onClick={() => handleSubMenuToggle("profile_menu")}
                >
                    <span className="flex gap-2 items-center justify-center font-bold hover:color-secondary-400 cursor-pointer">
                        {auth.user.display_name
                            ? auth.user.display_name
                            : auth.user.name}
                        <i className="fa-solid fa-caret-down"></i>
                    </span>
                    <div
                        className={`${
                            isSubMenuOpen("profile_menu")
                                ? "md:absolute"
                                : "hidden"
                        } min-w-[150px] right-0 text-black  shadow-none md:shadow-xl bg-white rounded justify-center py-2 px-4 flex flex-col gap-2 w-max mt-0 md:mt-4 top-0 md:top-2`}
                    >
                        <div
                            className="border-2 border-slate-200 p-1"
                            style={{
                                width: "80px",
                                height: "80px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                borderRadius: "50%",
                            }}
                        >
                            <img
                                src={
                                    auth.user.profile_photo_path
                                        ? "/" + auth.user.profile_photo_path
                                        : "/storage/users-avatar/avatar.png"
                                }
                                className="w-full h-full rounded-full"
                            />
                        </div>
                        {getUserRole() == "psychic" && (
                            <div className="font-bold">
                                <Link
                                    href={route(getUserRole() + ".dashboard")}
                                >
                                    Dashboard
                                </Link>
                            </div>
                        )}
                        <div className="font-bold">
                            <Link
                                href={route(
                                    auth &&
                                        auth.user &&
                                        auth.role != null &&
                                        auth.role?.[0]?.name + ".profile.edit"
                                )}
                            >
                                Profile
                            </Link>
                        </div>
                        <div className="font-bold">
                            <Link href={route("chatify")}>Chat</Link>
                        </div>
                        <div className="font-bold">
                            <Link
                                href="#"
                                className="text-danger"
                                onClick={handleLogout}
                            >
                                Logout
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
