import { useEffect, useRef } from "react";
import PrimaryButton from "../PrimaryButton";
import "react-datepicker/dist/react-datepicker.css";
import SecondaryButton from "../SecondaryButton";
import { setActiveStatus } from "@/Helper/pusher";

const ConfirmUserOnlinePopup = (props) => {
    const { audioRef, isOpen, closePopup } = props;
    let timeoutId;
    useEffect(() => {

        if (audioRef && audioRef.current) {
            if (isOpen) {
                // Set a timeout for 1 minute (60000 milliseconds)
                timeoutId = setTimeout(() => {
                    makeUserOffline();
                }, 20000);
                audioRef.current.loop = true;
                audioRef.current.play().catch((error) => {
                    // Handle any errors that occur during playback
                    console.error("Error playing audio:", error);
                });
            } else {
                // Pause and reset the audio when the popup is closed
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                clearTimeout(timeoutId);
            }
        }
        // Clear the timeout if the component is unmounted or if the popup is closed manually
        return () => {
            if (audioRef && audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            clearTimeout(timeoutId);
        };
    }, [isOpen]);
    const makeUserOffline = ()=>{
        $('#toggle_active_status').trigger('click');
        if (audioRef && audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        clearTimeout(timeoutId);
        closePopup();
    }
    const keepUserOnline = ()=>{
        window.location.reload();
    }
    return (
        <>
            <div
                className={`fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-30 z-20 ${
                    isOpen ? "" : "hidden"
                }`}
            >
                <div className="bg-white p-6 rounded shadow-lg w-4/5 md:w-2/6 ">
                    <div className="flex flex-col gap-4 w-full">
                        <p className="text-xl">
                            <strong>
                                Are you still online? Please confirm
                            </strong>
                        </p>
                        <div className="flex gap-4 justify-center flex-wrap  ">
                            <PrimaryButton onClick={keepUserOnline}>
                                Keep Online
                            </PrimaryButton>
                            <SecondaryButton onClick={makeUserOffline}>
                                Make Offline
                            </SecondaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ConfirmUserOnlinePopup;
