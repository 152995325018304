import { useState, useEffect, useRef } from "react";
import Footer from "@/Components/Shared/Footer";
import HeaderHome from "@/Components/Shared/HeaderHome";
import axios from "axios";
import { getPusherInstance, setActiveStatus } from "@/Helper/pusher";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Inertia } from "@inertiajs/inertia";
import PrimaryButton from "@/Components/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
    setIsStartChatPopupOpen,
    setConfirmUserPopupDetails,
    getConfirmUserPopupDetails,
    setActiveChat,
    getActiveChat,
} from "../ReduxToolkit/chatSlice";
import StartChatConfirmation from "@/Components/Popups/StartChatConfirmation";
import ConfirmUserOnlinePopup from "@/Components/Popups/ConfirmUserOnlinePopup";
import { getUserRole } from "@/Components/Shared/GenericFunctions";

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export default function Authenticated({ auth, header, children, page }) {
    const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY;
    const PUSHER_APP_CLUSTER = import.meta.env.VITE_PUSHER_APP_CLUSTER;
    const PUSHER_APP_CHANNEL = import.meta.env.VITE_PUSHER_APP_CHANNEL;
    const PUSHER_APP_PRESENCE_CHANNEL = import.meta.env
        .VITE_PUSHER_APP_PRESENCE_CHANNEL;

    const USER_ROLE = location.pathname.split("/")[1];
    const auth_user_role = getUserRole();
    const [showingNavigationDropdown, setShowingNavigationDropdown] =
        useState(false);
    const [unSeenChats, setUnSeenChats] = useState(0);
    const [isConfirmUserOnlinePopupOpen, setIsConfirmUserOnlinePopupOpen] =
        useState(false);
    const confirmUserPopupDetails = useSelector(getConfirmUserPopupDetails);
    const activeChat = useSelector(getActiveChat);
    const audioRef = useRef(new Audio("/assets/sound/notification.wav"));
    let pusherObj;
    let chatChannel;
    let test = "testing var";
    let clientListenChannelObj;
    let authId = auth.user ? auth.user.id : "";
    let access_token = auth.csrf_token;
    let url = $("meta[name=url]").attr("content");
    const dispatch = useDispatch();

    useEffect(() => {
        $('meta[name="csrf-token"]').attr("content", auth.csrf_token);
        console.log(
            "tokentest",
            $('meta[name="csrf-token"]').attr("content") +
                " - " +
                auth.csrf_token
        );

        if (authId) {
            pusherObj = getPusherInstance();
            // $('meta[name="csrf-token"]').attr("content", auth.csrf_token);
            access_token = auth.csrf_token;
            url = $("meta[name=url]").attr("content");
            console.log("client-contactItem");
            getNewChatCounts();
            console.log(pusherObj);
            clientListenChannelObj =
                pusherObj.channels.channels[`${PUSHER_APP_CHANNEL}.${authId}`];
            if (!clientListenChannelObj)
                clientListenChannelObj = pusherObj.subscribe(
                    `${PUSHER_APP_CHANNEL}.${authId}`
                );
            console.log(clientListenChannelObj);
            // listen to contact item updates event
            let existingPrivateCallbacks =
                clientListenChannelObj.callbacks["_callbacks"][
                    "_client-newMessage"
                ];
            // Search for a function with the specified name within the array
            let matchedPrivateCallback =
                existingPrivateCallbacks &&
                existingPrivateCallbacks.find(
                    (obj) => obj.fn.name === "handleNewMessage"
                );

            if (!matchedPrivateCallback) {
                clientListenChannelObj.bind(
                    "client-newMessage",
                    function (data) {
                        if (data.update_for == authId) {
                            console.log(data);
                            getNewChatCounts();
                        }
                    }
                );
            }

            // listen to seen event
            clientListenChannelObj.bind("client-chatSeen", function (data) {
                console.log(data);
                if (data.to_id == authId) {
                    if (data.seen == true) {
                        console.log(data);
                        getNewChatCounts();
                    }
                }
            });

            // presence channel [User Active Status]
            var activeStatusChannel = pusherObj.subscribe(
                PUSHER_APP_PRESENCE_CHANNEL
            );
            activeStatusChannel.bind(
                "pusher:subscription_succeeded",
                (members) => {
                    // This event is triggered when the subscription is successful.
                    // The 'members' parameter contains information about the current active users.
                    console.log("active_members", members);
                    console.log("members.myID", members.myID);
                    // $.each(members,function(i,member) {
                    let member_id = members.myID;
                    setActiveStatus(1, member_id);
                    // });
                }
            );

            activeStatusChannel.bind("pusher:subscription_error", (error) => {
                // This event is triggered when the subscription is successful.
                // The 'members' parameter contains information about the current active users.
                // console.log('active_members',members);
                // alert();
                console.log("subscription_error", error);
            });

            // Joined
            activeStatusChannel.bind("pusher:member_added", function (member) {
                setActiveStatus(1, member.id);
                // alert(member);
            });

            // Leaved
            activeStatusChannel.bind(
                "pusher:member_removed",
                function (member) {
                    // alert(member);
                    setActiveStatus(0, member.id);
                }
            );
            chatChannel = pusherObj.channels.channels["presence-chats"];
            if (!chatChannel)
                chatChannel = pusherObj.subscribe("presence-chats");
            const existingCallbacks =
                chatChannel.callbacks["_callbacks"]["_client-Chat"];
            // Search for a function with the specified name within the array
            let matchedCallback =
                existingCallbacks &&
                existingCallbacks.find(
                    (obj) => obj.fn.name === "handleChatEvent"
                );

            if (!matchedCallback) {
                // chatChannel.unbind("client-Chat", handleChatEvent);
                chatChannel.bind("client-Chat", handleChatEvent);
            }
            if (auth_user_role == "psychic" || auth_user_role == "member") {
                getActiveChatSession();
            }
        } else {
            dispatch(setActiveChat(null));
            console.log("LogIn to continue to Chat", authId);
            const timer = setInterval(() => {
                getUsersStatus();
            }, 5000);
            // Clear the timer when the component is unmounted
            return () => {
                clearTimeout(timer);
            };
        }
        // -------------------------------------
    }, [authId]);
    const getActiveChatSession = () => {
        axios
            .post(route(auth_user_role + ".chats.sessions"), {
                status: ["active"],
            })
            .then((response) => {
                dispatch(setActiveChat(response.data));
            })
            .catch((error) => {
                toast(error.response.data.message);
                reject(false);
            });
    };
    const getUsersStatus = () => {
        $.ajax({
            url: route("messenger.getUsersStatus"),
            method: "POST",
            data: {
                _token: access_token,
            },
            dataType: "JSON",
            success: (data) => {
                console.log("getUsersStatus");
                console.log(data);
                $.each(data.usersList, function (index, el) {
                    if (el.active_status) {
                        $(
                            '.user-status[data-user-id="' +
                                el.id +
                                '"]>.status-text'
                        ).text("ONLINE");
                        $(
                            '.user-status[data-user-id="' + el.id + '"]>span'
                        ).addClass("bg-green-500");
                        $(
                            '.user-status[data-user-id="' + el.id + '"]>span'
                        ).removeClass("bg-gray-500");
                    } else {
                        $(
                            '.user-status[data-user-id="' +
                                el.id +
                                '"]>.status-text'
                        ).text("OFFLINE");
                        $(
                            '.user-status[data-user-id="' + el.id + '"]>span'
                        ).addClass("bg-gray-500");
                        $(
                            '.user-status[data-user-id="' + el.id + '"]>span'
                        ).removeClass("bg-green-500");
                    }
                });
            },
            error: (err) => {
                console.error("Server error, check your response");
                console.log(err);
            },
        });
        // }
    };
    function getNewChatCounts() {
        // if (user_id != authId) {
        $.ajax({
            url: route("messenger.getNewChatCounts"),
            method: "POST",
            data: {
                _token: access_token,
            },
            dataType: "JSON",
            success: (data) => {
                console.log("getNewChatCounts");
                console.log(data);
                setUnSeenChats(data.unSeenChats ? data.unSeenChats.length : 0);
            },
            error: (err) => {
                console.error("Server error, check your response");
                console.log(err);
            },
        });
        // }
    }
    const handleConfirmation = function (member_id) {
        dispatch(setIsStartChatPopupOpen(false));
        localStorage.setItem(
            "init_chat",
            JSON.stringify({ member_id: member_id })
        );
        stopWatingForConfirmation('chat_init_confirm');
        Inertia.visit("/chatify/" + member_id);
    };
    const handleChatEvent = function (data) {
        if (data.notification_type == "chat_init_confirm") {
            if (data.psychic_id == parseInt(auth.user.id)) {
                dispatch(setIsStartChatPopupOpen(true));
                dispatch(setConfirmUserPopupDetails(data));
            }
        } else if (data.notification_type == "chat_cancel_confirm") {
            dispatch(setIsStartChatPopupOpen(false));
        } else if (data.notification_type == "inactive_user_alert") {
            if (data.psychic_id == parseInt(auth.user.id)) {
                setIsConfirmUserOnlinePopupOpen(true);
            }
        }
    };
    const stopWatingForConfirmation = (reason = 'cancel') => {
        pusherObj = getPusherInstance();
        chatChannel = pusherObj.channels.channels["presence-chats"];
        if (!chatChannel) chatChannel = pusherObj.subscribe("presence-chats");

        chatChannel.trigger("client-Chat", {
            notification_type: "stop_waiting_confirmation",
            reason:reason,
            psychic_id: auth.user.id,
            member_id: confirmUserPopupDetails.member_id,
        });
    };
    return (
        <div className="min-h-screen">
            <StartChatConfirmation
                key={Math.random()}
                closePopup={() => {
                    stopWatingForConfirmation();
                    dispatch(setIsStartChatPopupOpen(false));
                }}
                handleConfirmation={handleConfirmation}
                audioRef={audioRef}
            />
            <ConfirmUserOnlinePopup
                closePopup={() => {
                    setIsConfirmUserOnlinePopupOpen(false);
                }}
                audioRef={audioRef}
                isOpen={isConfirmUserOnlinePopupOpen}
            />
            <HeaderHome
                USER_ROLE={USER_ROLE}
                page={page}
                auth={auth}
                header={header}
                unSeenChats={unSeenChats}
            ></HeaderHome>
            <main className={activeChat ? "pt-12" : "px-0"} test={"testing"}>
                {children}
            </main>
            <Footer auth={auth} header={header}></Footer>
        </div>
    );
}
