import Pusher from "pusher-js";

let pusherInstance = null;
const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY;
const PUSHER_APP_CLUSTER = import.meta.env.VITE_PUSHER_APP_CLUSTER;
const PUSHER_APP_CHANNEL = import.meta.env.VITE_PUSHER_APP_CHANNEL;
const VITE_PUSHER_APP_PRESENCEC_CHANNEL = import.meta.env
    .VITE_PUSHER_APP_PRESENCEC_CHANNEL;
var access_token = $('meta[name="csrf-token"]').attr(
    "content"
);
var old_access_token = '';
let url = $("meta[name=url]").attr("content");

export const getPusherInstance = () => {
    access_token = $('meta[name="csrf-token"]').attr("content");
    if (!pusherInstance || access_token != old_access_token) {
        old_access_token = access_token;
        console.log("CSRF Token:", access_token);
        pusherInstance = new Pusher(PUSHER_APP_KEY, {
            authEndpoint: "/chatify/chat/auth",
            cluster: PUSHER_APP_CLUSTER,
            encrypted: true,
            auth: {
                headers: {
                    "X-CSRF-TOKEN": access_token,
                },
                params: {
                    "_token": access_token,
                },
            },
        });
    }

    return pusherInstance;
};
/**
*-------------------------------------------------------------
* Set Active status
*-------------------------------------------------------------
*/
export const setActiveStatus = (status, user_id) => {
    console.log(url);
   $.ajax({
       url: url + "/setActiveStatus",
       method: "POST",
       data: { _token: access_token, user_id: user_id, status: status },
       dataType: "JSON",
       success: (data) => {
        // alert(data);
        if(status){
            $(
                '.user-status[data-user-id="' +
                user_id +
                    '"]>.status-text'
            ).text("ONLINE");
            $(
                '.user-status[data-user-id="' + user_id + '"]>span'
            ).addClass("bg-green-500");
            $(
                '.user-status[data-user-id="' + user_id + '"]>span'
            ).removeClass("bg-gray-500");
            $('.messenger-list-item.m-list-active').find('.activeStatus').removeClass('!bg-gray-500');
            $('.messenger-list-item.m-list-active').find('.activeStatus').addClass('!bg-green-500');
        }
        else{
            $(
                '.user-status[data-user-id="' +
                user_id +
                    '"]>.status-text'
            ).text("OFFLINE");
            $(
                '.user-status[data-user-id="' + user_id + '"]>span'
            ).addClass("bg-gray-500");
            $(
                '.user-status[data-user-id="' + user_id + '"]>span'
            ).removeClass("bg-green-500");
            $('.messenger-list-item.m-list-active').find('.activeStatus').addClass('!bg-gray-500');
            $('.messenger-list-item.m-list-active').find('.activeStatus').removeClass('!bg-green-500');
        }
        return data;
           // Nothing to do
       },
       error: () => {
           console.error("Server error, check your response");
           return 0;
        },
   });
}
