import {useEffect} from 'react';
import AccountBalance from "@/Pages/Accounts/AccountBalance";
import AddBalanceForm from '../Forms/AddBalanceForm';

const AddBalancePopup = (props) => {
    const {isOpen,closePopup} = props
    if (!isOpen) {
        return null;
    }
    return (
        <>
         <div className="fixed inset-0 flex items-center justify-center z-[102] bg-black bg-opacity-30 ">
            <div className="bg-white p-6 rounded shadow-lg w-4/5 md:w-3/5">
                <div className="flex justify-end">
                    <button
                        className="text-black hover:font-semibold focus:outline-none"
                        onClick={closePopup}
                    >
                    X
                    </button>
                </div>
                <div className='h-100 max-h-96 overflow-y-auto'>
                    <AddBalanceForm closePopup={closePopup} userBalanceList={null} />
                </div>
            </div>
        </div>
        </>
    );
}
export default AddBalancePopup;
