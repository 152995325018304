import { Link, usePage } from "@inertiajs/inertia-react";
import React, { useEffect, useState } from "react";
import ToggleButton from "../ToggleButton";
import PrimaryButton from "../PrimaryButton";
import { getUserRole } from "./GenericFunctions";
import ProfileNavbar from "./ProfileNavbar";

export default function NavbarMain(props) {
    const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY;
    const PUSHER_APP_CLUSTER = import.meta.env.VITE_PUSHER_APP_CLUSTER;
    const PUSHER_APP_CHANNEL = import.meta.env.VITE_PUSHER_APP_CHANNEL;

    const { auth, unSeenChats, setIsPopupOpen } = props;
    const specialities = usePage().props.specialities;
    const [scrollPosition, setScrollPosition] = useState(0);
    const [menuVisible, setMenuVisible] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState({}); // State to track submenu open/close
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };
    const handleSubMenuToggle = (parent_speciality_id) => {
        const updatedSubMenuOpen = {};
        // Close all submenus
        for (const key in subMenuOpen) {
            updatedSubMenuOpen[key] = false;
        }
        // Open the clicked submenu
        updatedSubMenuOpen[parent_speciality_id] =
            !subMenuOpen[parent_speciality_id];
        setSubMenuOpen(updatedSubMenuOpen);
    };

    const isSubMenuOpen = (parent_speciality_id) => {
        return subMenuOpen[parent_speciality_id] || false;
    };
    const maxScroll = 1;
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    const getParentSpecialities = () => {
        return specialities.filter((e) => e.parent_speciality_id == null);
    };

    const hasChildSpecialities = (parent_speciality_id) => {};
    const getChildSpecialities = (parent_speciality_id) => {
        return specialities.filter(
            (e) => e.parent_speciality_id == parent_speciality_id
        );
    };

    return (
        <>

            <div
                onClick={toggleMenu}
                className="w-min float-right block md:hidden border-2 p-2 "
            >
                <i className="fa-lg fa-solid fa-bars"></i>
            </div>
            <div
                className={`main__menu__container   items-center ${
                    menuVisible ? "fixed left-0 w-full mt-24" : "hidden md:flex"
                }  md:relative`}
            >
            <div className="main__menu max-h-[80vh] overflow-y-auto md:overflow-y-visible flex gap-4 flex-col md:flex-row items-center py-4 md:py-0 w-full md:w-fit bg-white md:bg-transparent absolute md:relative">
                    {getParentSpecialities().map((obj, index) => {
                        return index < 3 ? (
                            <div
                                key={"top_" + obj.id}
                                className="parent__menu relative flex flex-col md:flex-row items-center order-2 md:order-0"
                                onClick={() => handleSubMenuToggle(obj.id)}
                            >
                                <span className="flex gap-2 items-center justify-center font-bold hover:color-secondary-400 cursor-pointer">
                                    {obj.name}
                                    {getChildSpecialities(obj.id).length !=
                                        0 && (
                                        <i className="fa-solid fa-caret-down"></i>
                                    )}
                                </span>
                                {getChildSpecialities(obj.id).length != 0 && (
                                    <div
                                        className={`${
                                            isSubMenuOpen(obj.id)
                                                ? "md:absolute"
                                                : "hidden"
                                        } text-black  shadow-none md:shadow-xl bg-white rounded justify-center py-2 px-4 flex flex-col gap-2 w-max mt-4 top-2`}
                                    >
                                        {getChildSpecialities(obj.id).map(
                                            (childObj, childIndex) => {
                                                return (
                                                    <div
                                                        key={
                                                            "sub_" + childObj.id
                                                        }
                                                    >
                                                        <Link
                                                            href={route(
                                                                "psychic",
                                                                childObj.slug
                                                            )}
                                                        >
                                                            {childObj.name}
                                                        </Link>
                                                    </div>
                                                );
                                            }
                                        )}
                                        <div key={"sub_all_" + obj.id}>
                                            <Link
                                                href={route(
                                                    "psychic",
                                                    obj.slug
                                                )}
                                                className="text-secondary  decoration-2  underline-offset-4 underline font-bold"
                                            >
                                                View All
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            ""
                        );
                    })}
                    <div
                        className="parent__menu relative flex flex-col md:flex-row items-center order-2 md:order-0"
                        onClick={() => handleSubMenuToggle("more")}
                    >
                        <span className="flex gap-2 items-center justify-center font-bold hover:color-secondary-400 cursor-pointer">
                            More
                            <i className="pl-1 fa-solid fa-caret-down"></i>
                        </span>
                        <div
                            className={`${
                                isSubMenuOpen("more") ? "md:absolute" : "hidden"
                            } text-black  shadow-none right-0 md:shadow-xl bg-white rounded justify-center py-2 px-4 max-w-full md:max-w-4xl flex flex-wrap gap-2 w-full md:w-max mt-4 top-2`}
                        >
                            {getParentSpecialities().map((obj, index) => {
                                return index >= 3 ? (
                                    <div
                                        className="flex-[24%]"
                                        key={"sub_more_" + obj.id}
                                    >
                                        <Link href={route("psychic", obj.slug)}>
                                            {obj.name}
                                        </Link>

                                        {getChildSpecialities(obj.id).length !=
                                            0 && (
                                            <div
                                                className={`${
                                                    isSubMenuOpen(obj.id)
                                                        ? "block"
                                                        : "hidden"
                                                } sub-submenu`}
                                            >
                                                {getChildSpecialities(
                                                    obj.id
                                                ).map(
                                                    (childObj, childIndex) => {
                                                        return (
                                                            <div
                                                                key={
                                                                    "sub_sub_more_" +
                                                                    childObj.id
                                                                }
                                                            >
                                                                <Link
                                                                    href={route(
                                                                        "psychic",
                                                                        childObj.slug
                                                                    )}
                                                                >
                                                                    {
                                                                        childObj.name
                                                                    }
                                                                </Link>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    ""
                                );
                            })}
                            <div className="flex-[24%]">
                                <Link
                                    className="text-secondary  decoration-2  underline-offset-4 underline font-bold"
                                    href={route("psychic")}
                                >
                                    All Psychics
                                    <i className="pl-2 fa-solid fa-arrow-right-long"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {auth.user &&
                        auth.role != null &&
                        (getUserRole() == "psychic" ||
                            getUserRole() == "member") && (
                            <div className="order-2 md:order-0 w-fit font-bold">
                                <Link href={route("chatify")}>
                                    Chat{" "}
                                    {unSeenChats ? (
                                        <span className="badge badge-pill badge-danger">
                                            {unSeenChats}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </Link>
                            </div>
                        )}
                    <div className="order-1 md:order-2">
                        {getUserRole() == "psychic" && (
                            <ToggleButton auth={auth} />
                        )}
                    </div>
                    {getUserRole() == "member" && (
                        <div className="hidden md:block order-1 md:order-2">
                            <PrimaryButton
                                onClick={() => {
                                    setIsPopupOpen(true);
                                }}
                            >
                                Buy Credit
                            </PrimaryButton>
                        </div>
                    )}
                    <ProfileNavbar auth={auth} />
                </div>
            </div>
        </>
    );
}
